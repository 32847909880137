/**
 * Slideshow
 */

// .slideshow {
//     @include border-radius($border-radius);
//     overflow: hidden;
//     max-width: 50rem;
// }

/**
 * Components: Slideshow
 */

// .slides {
//     overflow: hidden;
// }

// .slide {
//     background: $light;
//     float: left;
//     &-img {
//         @include ratio(4 3);
//         border-radius: 0;
//         margin: 0;
//         img {
//             width: auto;
//             left: 50%;
//             transform: translateX(-50%);
//         }
//     }
// }


// Owl Carousel
.owl-carousel.visible .owl-stage-outer {
    overflow: visible;
}

// Carousel
.owl-carousel {
    &[data-dots="true"] {
        margin-bottom: 5rem;
    }
    // Navigation
    .owl-nav {
        button.owl-prev,
        button.owl-next {
            @include transition;
            background: rgba($dark, .4);
            position: absolute;
            border-radius: 50%;
            top: 50%;
            width: 3rem;
            height: 3rem;
            text-align: center;
            z-index: 20;
            outline: none;

            &::before {
                border-color: $white;
                border-style: solid;
                border-width: 2px 2px 0 0;
                content: "";
                display: inline-block;
                height: .625rem;
                position: absolute;
                top: 1.25rem;
                width: .625rem;
                @include transition;
            }

            &:hover {
                background: rgba($dark, .5);
            }

            span {
                display: none;
            }
        }
        .owl-prev {
            @include translate(0,-50%);
            left: 1.4rem;

            &::before {
                transform: rotate(-135deg);
                left: 1.25rem;
            }

            &[class*="disabled"] {
                @include translate(-100%, -50%);
                opacity: 0;
                visibility: hidden;
                z-index: 10;
            }
        }
        .owl-next {
            @include translate(0,-50%);
            right: 1.4rem;

            &::before {
                transform: rotate(45deg);
                right: 1.25rem;
            }

            &[class*="disabled"] {
                @include translate(100%, -50%);
                opacity: 0;
                visibility: hidden;
                z-index: 10;
            }
        }
    }
    &.off {
        display: block;
    }
}

// Dots
.owl-dots {
    @include translate(0,50%);
    position: absolute;
    bottom: -1.875rem;
    width: 100%;
    text-align: center;

    &.disabled {
        display: none;
    }

    .owl-dot {
        display: inline-block;
        vertical-align: top;
        outline: none;

        span {
            display: block;
            width: 8px;
            height: 8px;
            margin: 10px;
            border-radius: 50%;
            background: rgba($dark,.25);
            @include transition();
        }

        &:hover {
            span {
                background: rgba($dark, .5);
            }
        }

        &.active {
            span {
                background: $primary;
                @include scale(1.5);
            }
        }

    }
}

.owl-carousel-single {
    .owl-stage-outer {
        border-radius: $border-radius;
        overflow: hidden;
    }
}

.owl-carousel-library {
    // Navigation
    .owl-nav {
        margin-top: 1rem;

        button.owl-prev,
        button.owl-next {
            @include translate(0,0);
            position: static;
            border-radius: $border-radius;
            background: none;
            border: $border-width solid transparent;

            &::before {
                color: $dark;
            }

            &:hover {
                background: transparent;
                border-color: $border-color;
            }
        }


        .owl-prev,
        .owl-nav {
            &[class*="disabled"] {
                @include translate(0, 0);
                visibility: visible;
                opacity: .5;
            }
        }

    }
}

// General
.owl-carousel {

    .owl-stage-outer {

        .owl-stage {
            .owl-item {
                @include transition();
                counter-increment: owlItem;

                // & img {
                //     height: 29.19rem;
                // }

            }
        }
    }

    &.show-siblings {
        .owl-stage-outer { overflow: visible; }
    }

    &.accent {
        .owl-item {
            opacity: .25;

            &.active {
                opacity: 1;
            }
        }
    }
}
