/**
 * Button
 */

.btn {
    font-size: 1.025rem;
    // padding-right: 1.5rem;
    margin-right: 1rem;
    color: $white;

    &:hover {
        color: $white;

        &:after {
            transform: translateX(5px);
            @include transition($transition-base);
        }
    }

    &:after {
        display: inline-block;
        content: " ›";
        padding-left: 1rem;
        @include transition($transition-base);
    }

    @include media-breakpoint-only(xs) {
        display: block;
        width: 100%;
    }
}

.btn-light {
    color: $body-color;
}

.close-btn {
    position: absolute;
    right: 3rem;
    top: 3rem;
    width: 1.5rem;
    height: 1.5rem;
    border:none;
    outline:none;
    background: none;
    z-index: 1;
    opacity: .75;

    &:before,
    &:after {
        background: $white;
        position: absolute;
        content: ' ';
        height: 1.5rem;
        width: 2px;
        top: 0;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    @include media-breakpoint-down(lg) {
        display: none;
    }
    &:hover {
        opacity: 1;
        transition: $transition-base;
    }
}


.navbar-toggler-collapse {
    // background: none;
    position: absolute;
    top: 1rem;
    right: 1rem;

    &.navbar-toggler {
        background-color: transparent;
    }

    .navbar-toggler-icon,
    .navbar-toggler-icon:after,
    .navbar-toggler-icon:before {
        background-color: $white;
    }
}


.btn-search {
    position: absolute;
    right: 2.25rem;
    top: 0.4rem;
    @include media-breakpoint-down(lg) {
        position: static;
        background-color: $gray-200;
        height: 3rem;
        width: 3rem;
        padding: 0.75rem;
        font-size: 1.125rem;
        border-radius: .3rem;
        &:hover {
            background-color: #ddd;
        }
    }
    @media(max-width: 1140px) {
        right: 1.25rem;
    }
}

.btn-secnav {
    color: $primary;
    font-size: 1.25rem;
    text-align: left;
    background: $white;
    border:none;
    outline:none;
    display: block;
    width: 100%;
    text-decoration: none;
    @include transition($transition-base);
    @include media-breakpoint-down(lg) {
        font-size: .9rem;
    }
    &:hover {
        text-decoration: none;
        color:$secondary;
        @include transition($transition-base);
    }
}

.btn-dropdown {
    &:after{
        margin-left: 5px;
        padding: 0;
        transform: rotate(90deg);
    }
    &:hover:after{
        margin-left: 5px;
        padding: 0;
        transform: rotate(90deg);
    }
}
