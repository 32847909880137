/**
 * Cards
 */

.card-text, .card-link {
    font-size: $small-font-size;
}

.card-text:not(.card-text--teaser-img-desc):not(.card-text-default) {
    font-family: $font-family-header;
    color: $light-grey;
    font-weight: $font-weight-semibold;
}

// card hover effects
.card {
    will-change: transform;
    [class^="card-"] {
        transition: $transition-base;
    }
    .card-link {
        display: inline-block;
        font-weight: 500;
        // border-bottom: 1px solid $dark-grey;
        &::after {
            content: '';
            display: block;
            width: 0;
            height: 1px;
            background: $dark-grey;
            transition: width .3s;
        }
    }

    &:not(.no-hover):hover {
        .card-img-teaser {
            transform: scale(1.1);
        }
        .card-title {
            color: $primary;
        }
        .card-link {
            color: $primary;
            &::after {
                background: $primary;
                width: 100%;
            }
        }
        .card-more {
            color: $primary;
        }
        .card-media-icons {
            background: rgba($dark, 1);
        }

    }
}

.card-body {
    text-align: left;
}

.card-media-icons {
    padding: .5rem;
    background: rgba($dark, .5);
    bottom: .5rem;
    right: 0;

    .icon {
        height: 1rem;
        width: 1rem;
    }
}

.card-media-icons--amount {
    font-size: .65rem;
    display: inline-block;
    font-family: $font-family-base;
    margin: 0 .5rem 0 .35rem;
    font-weight: $font-weight-bold;
}

.card-media-icons--amount__no-video {
    padding: 0;
    span {
        padding: 0;
        margin: 0 0 0 .25rem;
    }
}

.card-label {
    position: absolute;
    z-index: 1;
    padding: 0 5px;
    margin: 10px;
    font-size: $small-font-size;
    font-weight: $font-weight-bolder;
}

.label-sold {
    background-color: #dc3545;
    color: $white;
}