// ======
// Mixins
// ======

// Icon
@mixin icon-variant($background, $color) {
    background: rgba($background, .16);
    color: $color;
}

// Logo icon
@mixin logo-icon() {
    position: relative;
    &:before {
      background: url(../img/house.svg) 0 0 no-repeat;
      height: 1.5em;
      content: "";
      width: 1.5em;
      background-size: auto 100%;
      position: absolute;
      margin-left: -.25em;
      top: -.25em;
      pointer-events: none;
  }
}

// Ratio
@mixin ratio($ratio: 1 1, $selector: "&:before") {
    $selector: unquote($selector);
    position: relative;
    overflow: hidden;
    #{$selector} {
        content: '';
        display: block;
        padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
        height: 0;
    }
    img, figure {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}


// Fonts
@mixin font-base() {
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    font-family: $font-family-base;
    line-height: $line-height-base;
}

@mixin font-small() {
    display: inline-block;
    font-size: $font-size-sm;
    font-weight: $font-weight-base;
    letter-spacing: .05em;
    text-transform: uppercase;
}

// Linear gradient
@mixin linear-gradient ($firstColor, $secondColor) {
    background: $firstColor;
    background: linear-gradient(to bottom, $firstColor 0%, $secondColor 100%);
    background: -moz-linear-gradient(top, $firstColor 0%, $secondColor 100%);
    background: -ms-linear-gradient(top, $firstColor 0%, $secondColor 100%);
    background: -webkit-linear-gradient(top, $firstColor 0%,$secondColor 100%);
}

@mixin horizontal-gradient($firstColor, $secondColor, $stop: 100%) {
  background-color: $firstColor;
  background-image: -moz-linear-gradient(left, $firstColor 0%, $secondColor $stop);
  background-image: -webkit-linear-gradient(left, $firstColor 0%, $secondColor $stop);
  background: -ms-linear-gradient(left, $firstColor 0%, $secondColor $stop);
  background: linear-gradient(to right, $firstColor 0%, $secondColor $stop);
}

// Text gradient
@mixin text-gradient ($firstColor, $secondColor) {
    background-image: -webkit-linear-gradient(left, $firstColor, $secondColor);
    display: inline-block;
    color: $firstColor;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
}

// Helpers
@mixin grayscale() {
    filter: grayscale(1);
}

@mixin scale($scale) {
    transform: scale($scale);
}

@mixin translate($x,$y) {
    transform: translate($x,$y);
}

@mixin transtext($color: white, $opacity: .8) {
    color: rgba($color,$opacity);
}

// Overlay
@mixin overlay($overlay: .2, $selector: "&:before") {
    $selector: unquote($selector);
    position: relative;
    overflow: hidden;
    #{$selector} {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($black,$overlay);
        z-index: 2;
    }
}
