// Variables
$white:    #fff;
$gray-100: #f1f1f1;
$gray-200: #EFF1F4;
$gray-300: #DEE6F1;
$gray-400: #C5CEDB;
$gray-500: #869AB8;
$gray-600: #626262;
$gray-700: #434F62;
$gray-800: #26344A;
$gray-900: #091932;
$black:    #030D26;
// $light: #6d6d6d;


$green: #71BF44;
$dark-green:#599b33;
$purple: #581A73;
$dark-purple: #230431;
$light-grey: #6d6d6d;
$dark-grey: #030d26;


$blue: #084298;
$cyan: #5BBCF8;

$primary:$purple;
$secondary: $green;

$light: $gray-100;
$dark: $gray-900;

// Options
$enable-caret: false;

// Spacing
$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 1,
    2: $spacer * 2,
    3: $spacer * 3,
    4: $spacer * 4,
    5: $spacer * 5,
);

// Body
$body-bg: $white;
$body-color: $dark;

// Links
$link-color: $primary;
$link-shade-percentage: 30%;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;

// Grid columns
$grid-gutter-width: 2.5rem;

// Components
$border-color: $gray-400;
$border-radius:  .5rem;
$border-radius-sm: .25rem;
$border-radius-lg: 1rem;

$transition-base: all .4s ease-in-out;
$transition-fade: opacity .25s linear;
$transition-color: color .25s ease-in-out;
$transition-transform: transform .25s ease-in-out;
$transition-collapse:height .25s ease;

$aspect-ratios: (
  "1x1": 100%,
  "2x1": 0%,
  "3x2": calc(2 / 3 * 100%),
  "4x3": calc(3 / 4 * 100%),
  "3x4": calc(4 / 3 * 100%),
  "5x4": calc(4 / 5 * 100%),
  "4x5": calc(5 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);

// Typography
$line-height-base: 1.7;


// $font-size-root: 16px;
$font-size-base: 1rem;
$font-family-sans-serif: "Open Sans", sans-serif;
$font-size-sm: $font-size-base * .875;
$font-size-lg: $font-size-base * 1.125;

$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-family-h1: 'Roboto Slab', serif;
$font-family-header: 'Montserrat', sans-serif;



$h1-font-size: $font-size-base * 2.4;
$h2-font-size: $font-size-base * 1.4;
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base * .875;
$small-font-size: $font-size-base * .9;

$headings-color: $primary;
$headings-font-weight: $font-weight-semibold;
$headings-margin-bottom: .375em;

$display-font-weight: $headings-font-weight;

$lead-font-weight: $font-weight-base;


// Buttons
$input-btn-padding-y: .625rem;
$input-btn-padding-x: 1.25rem;
$input-border-radius: $border-radius-sm;

// Forms
$form-label-color: $gray-900;
$form-check-input-border-radius: $border-radius-sm;

// Breadcrumbs
$breadcrumb-font-size: .875rem;
$breadcrumb-margin-bottom: $spacer;
$breadcrumb-divider: quote("›");

// Buttons
//$btn-padding-y: .625rem;
//$btn-padding-x: 1.25rem;

$btn-border-width: 0;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

$btn-default-color: $white;
$btn-font-weight: $font-weight-semibold;
$btn-font-family: $font-family-header;
$btn-font-size: .875rem;

$btn-padding-y-sm: .375rem;
$btn-padding-x-sm: 1rem;
$btn-padding-y-lg: .8rem;
$btn-padding-x-lg: 1.9rem;

$btn-transition: color .25s ease-in-out, background-color .25s ease-in-out, border-color .25s ease-in-out, box-shadow .25s ease-in-out;

// Navs
$navbar-padding-y: 0;


// Navbar
$nav-link-color: $white;
$nav-link-font-weight: $font-weight-bold;
$navbar-nav-link-padding-x: 0;
$navbar-nav-link-padding-y: $spacer * 2;
$nav-link-padding-y: $spacer;

$navbar-toggler-padding-y: $spacer;
$navbar-toggler-padding-x: $spacer;


$navbar-light-color: $dark;
$navbar-light-hover-color: $cyan;
$navbar-light-active-color: $primary;

// Dropdowns
$dropdown-font-size: inherit;
$dropdown-item-padding-x: $navbar-nav-link-padding-x;
$dropdown-link-color: $navbar-light-color;
$dropdown-link-hover-color: $navbar-light-hover-color;
$dropdown-link-hover-bg: transparent;
$dropdown-link-active-color: $navbar-light-active-color;
$dropdown-link-active-bg: transparent;


// Cards
$card-spacer-y: $grid-gutter-width / 2;
$card-spacer-x: $grid-gutter-width / 2;
$card-border-radius: 0;
$card-border-color: $gray-300;
$card-cap-padding-y: $card-spacer-y;
$card-cap-padding-x: $card-spacer-x;
$card-cap-bg: transparent;
$card-bg: $white;
$card-img-overlay-padding: $grid-gutter-width / 2;

// Tables
$table-cell-padding-x: 1rem;

// Offcanvas
$offcanvas-padding-y: $grid-gutter-width / 2;
$offcanvas-padding-x: $grid-gutter-width / 2;
$offcanvas-horizontal-width: 25rem;


// hr
$hr-color: $secondary;
$hr-height: 4px;
