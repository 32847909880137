.page {
    &-item {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    &-link {
        border:none;
        color: #626262;
        text-align: center;
        min-width: 2.25rem;
        padding: 0.1rem 0.75rem;
        @include media-breakpoint-down(lg) {
            padding: 0.375rem 0.75rem;
        }
    }
}

.page-item:last-child .page-link {
    // border-top-right-radius: 50%;
    // border-bottom-right-radius: 50%;
}

.page-item:first-child .page-link {
    // border-top-left-radius: 50%;
    // border-bottom-left-radius: 50%;
}
