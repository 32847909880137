.teaser {
    color:$primary;
    
    & .subtitle  {
        font-style: normal;
    }

    &-content {
        & .card {
            color:#626262;
        }
    }
}

.card-teaser.teaser-logo {
    figure {
        background-color: unset;
    }
    img.card-img-teaser {
        transform: scale(.9);
    }
    &:hover {
        img.card-img-teaser {
            transform: scale(1);
        }
    }
}
