/**
 * Navbar
 */
// .wrapper_header {
//     position: fixed;
//     top: 0;
// }

nav .container {
    @include media-breakpoint-down(lg) {
        width: 100%;
        max-width: none;
    }
}

.navbar-brand {
    display: inline-block;
    // height: calc(100% - 2rem);
    h2 {
        overflow: hidden;
        text-indent: -999em;
    }
 }

 .navbar-brand-big {
    width: 20rem;
    background-position: left center;
 }

 .nav-item {
    @include media-breakpoint-up(lg) {
        min-height: 6rem;
    } 
 }

.nav-link {
    a {
        text-decoration: none;
    }
    border-bottom: .25rem solid transparent;
    font-size: $font-size-sm;
    font-family: $btn-font-family;
    margin: 0 $spacer;
    // added an extra .25rem (because of the border bottom) to the padding top to nicely center the nav-link in the container
    padding: 2.25rem 0 2rem 0;
    @include transition($transition-base);
    
    @include media-breakpoint-down(xl) {
        // font-size: $font-size-sm;
        margin: 0 ($spacer * .625);
    }

    &.active,
    &:hover {
        color: $primary;
        border-bottom: .25rem solid $primary;
        @include transition($transition-base);
        @include media-breakpoint-down(lg) {
            color: $secondary !important;
        }
    }

}

// No right margin on the last link
.navbar-nav > .nav-item:last-child {
    .nav-link {
        margin-right: 0;
    }
}

.secnav {
    background: $primary;

    @include media-breakpoint-down(lg) {
        // this is not really OK, but it has to do for know
        // update: should be fixed, but leaving it in place 'til' I'm sure
        &.collapse {
            display: flex;
        }
    }

    @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        // top: -$spacer;
        right: 0;
        min-width: 50.5rem;
    }

    &-link {
        font-weight: $font-weight-medium;
        color: $nav-link-color;
        text-decoration: none;
        @include transition($transition-base);

        &.active {
            color: $secondary;
        }

        &:hover {
            color: shift-color($white, $link-shade-percentage);
            @include transition($transition-base);
        }

        &-main {
            font-size: 1.25rem;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 2.75rem;
                left: 0;
                bottom: 0;
                height: 2px;
                width: 3rem;
                /* or 100px */
                background: $white;
            }
        }
    }
}

.navbar {
    // Brand
    font-family: $font-family-header;
    font-weight: $font-weight-bold;

    @include media-breakpoint-down(lg) {
        padding: 0;
    }

    &-toggler,
    &-phone {
        height: 3rem;
        width: 3rem;
    }

    &-phone {
        padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
        border-radius: $navbar-toggler-border-radius;

        .icon {
            vertical-align: top;
        }
    }

    &-toggler {
        border: 0;
        border-radius: .3rem;

        &:focus {
            box-shadow: none;
            outline: none;
        }

        // &:hover {
        //     background-color: #ddd;
        // }

        &[aria-expanded="true"] {
            .navbar-toggler-icon {
                transform: translate3d(0, 0.375rem, 0) rotate(45deg);
            }

            .navbar-toggler-icon:before {
                transform: rotate(-45deg) translate3d(0, -0.25rem, 0);
                opacity: 0;
            }

            .navbar-toggler-icon:after {
                transform: translate3d(0, -0.75rem, 0) rotate(-90deg);
            }
        }

        &-icon {
            margin: -.4375rem 0 0 -.75rem;
        }

        &-icon,
        &-icon:before,
        &-icon:after {
            border-radius: .625rem;
            width: 1.5rem;
            height: .125rem;
            background-color: $white;
            position: absolute;
            transition: transform .3s ease;
        }

        &-icon:before,
        &-icon:after {
            content: "";
            display: block;
        }

        &-icon:before {
            top: 0.375rem;
            transition: transform .3s ease, opacity .3s ease;
        }

        &-icon:after {
            top: 0.75rem;
        }
    }


    .badge {
        color: $white;
        background: $danger;
        display: inline-block;
        vertical-align: top;
        height: 1rem;
        font-size: $font-size-base * .75;
        line-height: 1rem;
        width: 1rem;
        border-radius: 50%;
        padding: 0;
        text-align: center;
        margin-top: 0;
        @include media-breakpoint-up(sm) {
            margin-top: -.25rem;
        }
    }

    @include media-breakpoint-up(lg) {
        .nav-item {
            &:hover {
                .dropdown-menu {
                    transform: translateY(0);
                    visibility: visible;
                    opacity: 1;
                }
            }

            .icon-arrow-down {
                margin-right: -.25rem;
                vertical-align: middle;
            }
        }

        .dropdown-menu {
            transition: all .2s ease-in-out;
            transition-property: opacity, visibility, transform;
            transform-origin: top center;
            transform: translateY(-1rem);
            visibility: hidden;
            opacity: 0;
            display: block;

            &.show {
                transform: translateY(0);
                visibility: visible;
                opacity: 1;
            }
        }

        .navbar-phone {
            display: none;
        }
    }
}


// @media(max-width: 1140px) {
//     .navbar .nav-link {
//         margin: 0.75rem .7rem;
//     }
// }

@include media-breakpoint-down(lg) {
    .navbar-collapse {
        background: $primary;
        display: block;
        overflow-y: auto;
        position: fixed;
        left: -.5 * $spacer;
        top: 0;
        transform: translateX(-100%);
        padding: 0;
        height: 100%;
        max-width: 30rem;
        transition: transform .25s ease-in-out;

        &.show {
            transform: translateX(-0);
        }

        .nav-link {
            padding: 0;
            font-size: $font-size-base;

            &:hover {
                color: $green;
            }
        }
    }

    & .secnav {
        &-brand {
            & h2 {
                height: 4rem;
                width: 10rem;
                background-size: contain;
                margin-bottom: 2rem;
            }
        }
    }
}