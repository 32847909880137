.features {
    //&-color {
    //    color:$white;
    //}

    &-primary-color {
        color:$primary;
        .icon > svg {
            fill:$primary;
        }
    }

    &__text-col{
        font-size: $font-size-sm;
        line-height: 1.5rem;
        color:$dark-grey;
    }

    &__title-col {
        margin-bottom: .2rem;
    }

    &-box {
        column-count: 2;
    }

    & .subtitle {
        & a {
            color: $secondary;
            &:hover {
                text-decoration: underline;
                color:$white;
            }
        }
    }

    & svg {
        width: 6rem;
        height: 6rem;
        fill:$white;
        @include media-breakpoint-down(sm) {
            width: 5rem;
            height: 5rem;
        }
    }

    &__title,
    &__text,
    &__title-col{
        color:$secondary;
    }

    &__title {
        margin-top: 1.2rem;
        font-family:$font-family-header ;
    }

    &__text {
        font-size: $font-size-base;
        line-height: .7rem;
    }

}


