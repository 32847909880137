/**
 * Section
 */

#content {
	background: $white;
	// overflow: hidden;
	width: 100%;
}

.section-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	background-color: $white;
}

.content {
	position: relative;
	z-index: 1;
	+ .content {
		padding-top: $spacer * 1.5;
	}
}

.text-center {
	.content:not(.teasers) {
		margin-left: auto;
		margin-right: auto;
	}
}
.columns {
	+ .columns {
		padding-top: $grid-gutter-width;
	}
}
ul.row {
	@include list-unstyled();
	margin-bottom: 0;
}

// SECTION
.section {
	margin: auto;
	position: relative;
	padding: $grid-gutter-width 0;

	@include media-breakpoint-up(lg) {
		padding: ($grid-gutter-width * 1.5) 0;
	}
	@include media-breakpoint-up(xl) {
		padding: ($grid-gutter-width * 2) 0;
	}

	// &:not(.hasbg) + .section:not(.hasbg) {
	// 	padding-top: 0;
	// }

	// Padding
	&-no-padding {
		padding: 0;
		&-top {
			padding-top: 0;
		}
		&-bottom {
			padding-bottom: 0;
		}
	}

	// Border
	&-border {
		&-top {
			border-top: 1px solid $light;
		}
		&-bottom {
			border-bottom: 1px solid $light;
		}
	}

}



// Section with shapes (dividers)
.shape {
	overflow: hidden;
	pointer-events: none;
    position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	>*  {
		transform: scale(2);
		transform-origin: top center;
		height: auto;
    	width: 100%;
	}
	path {
		fill: $white;
		stroke: none;
	}
}

// Hack to add a margin bottom to all but the last button in the content builder buttons
.button {
	a.btn:not(:last-child) {
		@include media-breakpoint-down(sm) {
			margin-bottom: $spacer;
		}
	}
}


.bg-primary-color {
	@extend .bg-primary;
}

.bg-secondary-color {
	@extend .bg-secondary;
}

.bg-primary-color .card-text p {
	color: $white !important;
}

.bg-secondary, 
.bg-primary,
.bg-primary-color {
	
	color: $white;
	
	h2, 
	h3, 
	h4, 
	dt {
		color: $white;
	}

	// change the color of links depending on the background color of the section
	a:not([class]) {
		color: $white;
		@include transition($transition-base);
		
		&:hover {
			color: shift-color($white, $link-shade-percentage);
			@include transition($transition-base);

		}
	}
}


.bg-cover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	z-index: 0;
	figure,
	img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}


.bg-video {
	&-media {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: "object-fit: cover;";
		top: 0;
		left: 0;
	}
}