/**
 *  Forms
 */

.js-form-tabs {
  .form-section {
    display: none;
    &.current {
      display: inherit;
    }
  }
  .form-steps {
    display: flex;
  }
}

.content.form {
  text-align: left;
  form {
    max-width: 45rem;
  }
}

label, .label {
  font-size: $font-size-base;
  line-height: $line-height-base;
}
.required {
  .form-label:after {
    content: " *";
    color: $danger;
  }
}

.form-check-input {
  @include media-breakpoint-up(lg) {
    // margin-top: .5rem;
  }
}

.invalid-feedback.filled {
  display: block;
}

.gdpr {
  display: block;
  font-size: $font-size-sm;
  line-height: 1.25rem;
}

.form-section {
    font-family: $font-family-header;
    font-size: $font-size-sm;
    color:$gray-700;
    & .custom-label {
        & a {
            color:$secondary;
            &:hover {
                color:$primary;
                transition: $transition-base;
            }
        }
    }
}


.form {
    &__title {
        font-family: $font-family-sans-serif;
        margin-bottom: 2.5rem;
    }
    &__text {
        font-family:$font-family-header ;
        font-size: $font-size-sm;
        width: 76%;
        line-height: 1.625rem;
        margin-bottom: 4rem;
        @include media-breakpoint-down(md) {
           width: 100%;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 2rem;
        }
    }
}

#fields-gender,
#fields-shippingMethod,
#fields-buildingPlot {
    display: flex;
    flex-wrap: wrap;
}

#fields-shippingMethod {
    align-items: baseline;

    & .invalid-feedback {
        margin-top: 0;
    }

}


.form-check {
    margin-right: 2rem;
}

.form-control {
    border-color: #ebebeb;
}

.form-group {
    padding-top: 1rem;
    position: relative;
}

.form-label {
    position: absolute;
    top: 30px;
    left: 24px;
    color: $gray-600;
    font-size: $font-size-sm;
    font-family:$font-family-header ;
}

.form-check-label {
  font-size: $font-size-sm;
}

.multiplechoice-label {
  position: static;
}

.multiplechoice {
    & .form-check-input.is-invalid~.form-check-label,
    & .form-check-input.is-invalid,
    & .was-validated .form-check-input:invalid~.form-check-label,
    & .form-check-input.is-valid~.form-check-label,
    & .was-validated .form-check-input:valid~.form-check-label{
        color: #7b7b7b;
    }

    & .form-check-input.is-invalid, .was-validated .form-check-input:invalid,
    & .form-check-input.is-valid, .was-validated .form-check-input:valid {
        border-color: #7b7b7b;
    }

    & .form-check-input.is-valid:checked,
    & .was-validated .form-check-input:valid:checked {
        background-color: $primary;
    }

    & .form-check-input.is-valid:focus,
    & .was-validated, .form-check-input:valid:focus {
        box-shadow: 0 0 0 0.25rem rgba(88,26,115,.25);
    }
}

.form-section {
    color:#7b7b7b;
}

