/**
 * Offcanvas
 */

.filter {
    label {
        cursor: pointer;
        user-select: none;
        color: $body-color;
        @include transition($transition-color);
        &:hover {
            color: $primary;
            @include transition($transition-color);
        }
    }

    .filter-group-checkbox{
        border: 1px solid $gray-400;
        border-radius: .25rem;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        ul{
            padding: .625rem .25rem .625rem 1.75rem;
        }
    }

    .form-check-input {
        margin-top: 0;
    }

    .form-check-input[type=checkbox] {
        margin-right: .5rem;
    }

    input:checked { color: $primary; }

    ul {
        @include list-unstyled;
        margin-bottom: $grid-gutter-width / 2;

        li {
            display: inline-block;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}


input:disabled {
    & + label {
        cursor: no-drop;
        opacity: .5;
    }

}
