/**
 * Call to action
 */

.cta {
    // border-radius: .5rem;
    padding: 3rem 1rem;
    & p {
        color:$white;
    }
    &__title {
        line-height:2.312rem ;
    }
}

.footer-cta {
    font-family: $font-family-h1;
}

.mobile_cta {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 500;
}



