/**
 * Team
 */
.content.team {
    .card-img-top {
        figure {
            background: url(../img/person.svg) no-repeat;
            background-size: 80%;
            background-position: bottom;
        }
    }
    .team-contact {
        list-style: none;
        padding-left: 0;

        .card-team-mail {
            color: $secondary;

            &:hover {
                color: $primary;
            }
        }
    }
}

.card-hasinfo {
    .card-img-top {
        @include media-breakpoint-up(md) {
            // border-radius: $card-border-radius 0 0 0;
        }
    }
}