/**
 *  Footer
 */


.footer {
    font-size: $font-size-sm;

    &-request {
        margin-bottom: .25rem;
    }
}

.footer-main-list--item a {
    position: relative;
    margin-left: .5rem;
    transition: $transition-base;

    &:hover {
        color: $secondary;
        transition: $transition-base;
    }

    &:before {
        content:"›";
        position: absolute;
        width: 2px;
        height: 2px;
        margin-left: -0.6rem;
    }
    
}

.subfooter {
    background: $dark-purple;
    font-size: $font-size-sm;
    font-family: $font-family-header;
}


.footer-legal-info {
    p {
        margin-bottom: 0;
    }
    a {
        color: $secondary;
    }
}

// Keep footer at the bottom of the page
@media only screen and (min-width: 1025px) and (min-height: 769px) {
	html, body {
		height: 100%;
	}
	body {
		display: flex;
		flex-direction: column;
	}
	#content {
		flex-shrink: 0;
	}
	.footer {
		margin-top: auto;
	}
}