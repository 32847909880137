/**
 * Google Reviews
 */

.review-recap {
    display: flex;
    gap: .5rem;
    align-items: center;
    margin-block: $spacer * 1.25;
    will-change: transform;
    transition: $transition-base;
    .icon {
        width: 2.75rem;
        height: 2.75rem;
        transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    .title {
        // font-size: $h5-font-size;
        font-weight: $font-weight-bold;
        font-size: $font-size-sm;
    }
    .rating {
        display: flex;
        // display: grid;
        // grid-template-columns: repeat(3, auto);
        gap: $spacer;
        margin-block-start: -.5rem;
        align-items: center;
        // @include media-breakpoint-only(md) {
        //     grid-template-columns: repeat(2, auto);
        //     grid-template-rows: repeat(2, auto);
        // }
        &-value {
            font-size: $h3-font-size;
            color: $gray-600;
            font-weight: $font-weight-bold;
        }
        &-total {
            font-size: $font-size-sm;
            color: $gray-600;
            a {
                color: inherit;
                text-decoration: none;
                text-decoration: underline rgba($secondary, 0);
                // &:hover {
                //     color: $secondary;
                //     text-decoration-color: rgba($secondary, .6)
                // }
            }
        }
    }
    &:hover {
        cursor: pointer;
        // transform: translateY(-.25rem);
        // @extend .shadow-sm;
        .icon {
            transform: scale(1.2);
            transform-origin: center right;
        }
        .rating a {
            color: $secondary;
            text-decoration-color: rgba($secondary, .6)
        }
    }
}

.review {
    display: flex;
    flex-direction: column;
    gap: $spacer * .75;
    padding: 1rem;
    margin-block-end: 1.25rem;
    &-user {
        display: flex;
        gap: $spacer * .5;
        align-items: end;
        &-pic {
            width: 3rem;
            height: 3rem;
        }
        &-name {
            font-weight: $font-weight-bold;
            font-size: $font-size-sm;
        }
    }
    &-content {
        font-size: $small-font-size;
    }
}

.reviews {
    background-color: $white;
    .owl-nav {
        display: none;
    }
    .owl-dots {
        .owl-dot:not(.active) span {
            background: $gray-200;
        }
        .owl-dot:not(.active):hover {
            span {
                background: $gray-500;
            }
        }
    }
}

.bg-primary-color {
    span.rating-value {
        color: $gray-100;
    }
    .rating-total a {
        color: $gray-300;
    }
    .review {
        color: $dark;
    }
}

.review-title {
    @extend .display-6;
    @include media-breakpoint-up(sm) {
        font-size: $font-size-base * 2;
    }
}

/* Star ratings */
[data-star] {
    text-align:left;
    font-size: 16px;
    font-style:normal;
    display:inline-block;
    position: relative;
    unicode-bidi: bidi-override;
}
[data-star]::before { 
    display:block;
    content: '★★★★★';
    color: $gray-300;
}
[data-star]::after {
    white-space:nowrap;
    position:absolute;
    top:0;
    left:0;
    content: '★★★★★';
    width: 0;
    color: #eabb2e;
    overflow:hidden;
    height:100%;
}

/*! purgecss start ignore */
[data-star^="0.1"]::after,[data-star^=".1"]::after{width:2%}
[data-star^="0.2"]::after,[data-star^=".2"]::after{width:4%}
[data-star^="0.3"]::after,[data-star^=".3"]::after{width:6%}
[data-star^="0.4"]::after,[data-star^=".4"]::after{width:8%}
[data-star^="0.5"]::after,[data-star^=".5"]::after{width:10%}
[data-star^="0.6"]::after,[data-star^=".6"]::after{width:12%}
[data-star^="0.7"]::after,[data-star^=".7"]::after{width:14%}
[data-star^="0.8"]::after,[data-star^=".8"]::after{width:16%}
[data-star^="0.9"]::after,[data-star^=".9"]::after{width:18%}
[data-star^="1"]::after{width:20%}
[data-star^="1.1"]::after{width:22%}
[data-star^="1.2"]::after{width:24%}
[data-star^="1.3"]::after{width:26%}
[data-star^="1.4"]::after{width:28%}
[data-star^="1.5"]::after{width:30%}
[data-star^="1.6"]::after{width:32%}
[data-star^="1.7"]::after{width:34%}
[data-star^="1.8"]::after{width:36%}
[data-star^="1.9"]::after{width:38%}
[data-star^="2"]::after{width:40%}
[data-star^="2.1"]::after{width:42%}
[data-star^="2.2"]::after{width:44%}
[data-star^="2.3"]::after{width:46%}
[data-star^="2.4"]::after{width:48%}
[data-star^="2.5"]::after{width:50%}
[data-star^="2.6"]::after{width:52%}
[data-star^="2.7"]::after{width:54%}
[data-star^="2.8"]::after{width:56%}
[data-star^="2.9"]::after{width:58%}
[data-star^="3"]::after{width:60%}
[data-star^="3.1"]::after{width:62%}
[data-star^="3.2"]::after{width:64%}
[data-star^="3.3"]::after{width:66%}
[data-star^="3.4"]::after{width:68%}
[data-star^="3.5"]::after{width:70%}
[data-star^="3.6"]::after{width:72%}
[data-star^="3.7"]::after{width:74%}
[data-star^="3.8"]::after{width:76%}
[data-star^="3.9"]::after{width:78%}
[data-star^="4"]::after{width:80%}
[data-star^="4.1"]::after{width:82%}
[data-star^="4.2"]::after{width:84%}
[data-star^="4.3"]::after{width:86%}
[data-star^="4.4"]::after{width:88%}
[data-star^="4.5"]::after{width:90%}
[data-star^="4.6"]::after{width:92%}
[data-star^="4.7"]::after{width:94%}
[data-star^="4.8"]::after{width:96%}
[data-star^="4.9"]::after{width:98%}
[data-star^="5"]::after{width:100%}
/*! purgecss end ignore */