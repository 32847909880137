.contact {
    color:$dark-grey;

    & a {
        color:inherit;
    }

    &-mail {
        &:hover {
            color:$secondary;
        }
    }
}
