/**
 * Map
 */

.map {
	height: 30rem;
	width: 100%;
	color: $body-color;
	&-wrap {
		@include border-radius($border-radius);
		overflow: hidden;
		background: $light;
	}
	&-lg {
		@include media-breakpoint-up(lg) {
		height: 40rem;
		}
	}
	&-fh {
		background: $card-cap-bg;
		position: relative;
		border-top: 6rem solid $primary;
		height: 100vh;
		@include media-breakpoint-up(md) {
			border-top-width: 7.5rem;
		}
	}
	img {
		border-radius: 50%;
	}
	img[role="presentation"] {
		border-radius: 0;
		box-shadow: 0;
	}
	&-home {
		border-radius: 0;
		background: $dark;
		max-width: none;
	}

	&-content {
		font-family: $font-family-base;
		line-height: $line-height-base;
		font-weight: $font-weight-base;
		font-size: $font-size-sm;
		color: inherit;
		padding: .5rem 4rem .5rem .5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 20rem;
		position: relative;
		p {
			margin: 0;
		}
		strong {
			font-weight: $font-weight-bold;
		}
	}
	h5 {
		margin: 0;
		font-size: $h4-font-size;
		line-height: 1.5rem;
		@include transition;
		&:only-child {
			text-align: center;
		}
	}
	&-more {
			position: absolute;
			top: 0.5rem;
			// margin-top: -1.5rem;
			right: 0;
			padding: 0;
			width: 3rem;
			height: 3rem;
			overflow: hidden;
			text-indent: -999em;
			&::before {
				border-color: $primary;
				border-style: solid;
				border-width: 2px 2px 0 0;
				content: "";
				display: inline-block;
				height: .5rem;
				position: absolute;
				top: 1.25rem;
				width: .5rem;
        transform: rotate(45deg);
        right: 1.25rem;
				@include transition;
			}
			&:hover {
				&:before {
					border-color: $light;
				}
			}
		}
		&-filter {
			background: $primary;
			position: relative;
			z-index: 10;
			.form-control {
				margin-bottom: 1rem;
			}

			@include media-breakpoint-up(md) {
				margin-top: -2rem;
				padding-bottom: 1rem;
			}
    //   display: flex;
    //   @include media-breakpoint-only(xs) {
    //     display: block;
    //     padding: 1.5rem 4.5rem;
    //   }
    //   .custom-select{
    //     margin: auto .5rem;
    //     color: $gray-500;
    //     font-size: $h6-font-size;
    //     @include media-breakpoint-only(xs) {
    //       display: block;
    //       margin: auto;
    //     }
    //     option {
    //       font-size: $h6-font-size;
    //       &:hover {
    //         color: $gray-500;
    //       }
    //       &:active {
    //         color: $dark;
    //       }
    //     }
    //   }
    //   .form-control {
		// 		font-size: $font-size-base;
    //     @include media-breakpoint-only(xs) {
    //       font-size: $small-font-size;
    //       margin-left: 10px;
    //       padding-bottom: 0.8rem;
    //     }
		// 	}
		// }
    // &-search{
    //   width: 140rem;
    //   @include media-breakpoint-only(xs) {
    //     width: auto;
    //     padding-bottom: 1rem;
    //   }
    //   .input-group-prepend {
    //     @include media-breakpoint-only(xs) {
    //       display: none;
    //     }
    //   }
    //   @include media-breakpoint-only(xl) {
    //     width: 92rem;
    //   }
    }
		// &-placeholder {
		// 	background: orange;
		// 	position: absolute;
		// 	z-index: 20;
		// 	height: 6rem;
		// 	width: 14rem;
		// }
}
a.map-content {
		min-height: 4rem;
		&:hover {
			color: inherit;
			h5 {
				color: $primary;
			}
			.btn {
				@extend :hover;
			}
		}
}
.ui-front {
    z-index: 100;
}

.ui-menu {
	background: $white;
	border-radius: $border-radius;
	box-shadow: $box-shadow;
	list-style: none;
	padding: 0;
	margin: 0;
	display: block;
  &-item {
		+ .ui-menu-item {
			border-top: 1px solid $gray-200;
		}
		&-wrapper {
			display: block;
			padding: .5rem 1rem;
			&:hover, &.ui-state-active {
				h5 {
					color: $primary;
				}
			}
		}
    &-content {
			padding-left: 4rem;
			min-height: 3rem;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		h5 {
			color: $dark;
			margin: 0;
			font-size: $font-size-base;
			line-height: 1.5rem;
			@include transition;
		}
		p {
			color: $body-color;
			line-height: 1.5rem;
			font-size: $font-size-sm;
			margin: 0;
			font-weight: normal;
			&:empty-child {
				display: none;
			}
		}
    .image {
			position: absolute;
			top: 0;
			left: 0;
        width: 3rem;
				height: 3rem;
			img {
        border-radius: $border-radius;
        width: 3rem;
				height: 3rem;
				object-position: 50% 50%;
				object-fit: cover;
      }
    }
  }
}

.section-map {
	padding-top: 0;
	margin-top: 2rem;
	position: relative;
	overflow: hidden;
	&:before {
		background: $white;
		z-index: 0;
		content: "";
		width: 120%;
		height: 60%;
		position: absolute;
		bottom: 80%;
		left: -10%;
		transform: skew(0,-5deg);

	}
}
