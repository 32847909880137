/**
 * Cookies
 */

.cc-window {
    @extend .shadow-lg;
    font-family: $font-family-base;
    font-size: $font-size-sm;
    line-height: 1.25rem;
    z-index: 999;
    &.cc-banner, &.cc-floating {
        padding: .75rem 1.25rem;
    }
    &.cc-floating.cc-theme-edgeless .cc-message {
        margin: .5rem .5rem 1.25rem .5rem;
    }
    .cc-link {
        color: inherit;
        padding: 0;
        margin-left: 0;
        opacity: 1;
        transition: color .25s ease-in-out;
        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }
    .cc-compliance {
        @extend .d-grid;
    }
    .cc-btn {
        font-size: $font-size-sm;
        width: 100%;  
        @extend .btn;
        @extend .btn-secondary;
    }
}