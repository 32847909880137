/**
 * Offcanvas
 */

.offcanvas {
    &-footer {
        padding: $offcanvas-padding-y $offcanvas-padding-x;
    }
    @include media-breakpoint-up(md) {
        border: 1px solid $card-border-color;
        border-radius: $card-border-radius;
        position: relative;
        transform: none;
        transition: none;
        visibility: visible !important;
        z-index: inherit;
        width: 100%;
        &-toggler, &-header, &-footer {
            display: none;
        }
    }
}