/**
 * Type
 */

html {
    box-sizing: border-box;
    max-width: 100vw;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

body {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a,
a:link, 
a:visited {
    cursor: pointer;
    transition: $transition-base;
}

a.tel, 
a[href^="tel:"]:not(.btn) {
    color: inherit;
    text-decoration: none;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-header;
    color: $dark-grey;
    
    small {
        font-size: .5em;
    }

    em {
        color: $secondary;
        font-style: normal;
    }
}


h4 {
    font-weight: $font-weight-base;
    font-size: $h3-font-size;
}



p + h2, p + h3,
ul + h2, ul + h3,
ol + h2, ol + h3,
.table-responsive + h2, .table-responsive + h3 {
    padding-top: $spacer * 1.5;
}

.text-balance {
    text-wrap: balance;
}



.richtext {
    max-width: 60rem; // 960px
    ul:not([class]) {
        @include list-unstyled();
        li {
            position: relative;
            margin-left: 1.25rem;
            font-size: $font-size-base;
            &:before {
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 14'><path fill='#{$secondary}' d='M4.40535 12.293L5.5575 13.707L11.0225 6.99997L5.5575 0.292969L4.40535 1.70697L7.90335 5.99997H0.0925293V7.99997H7.90335L4.40535 12.293Z'></path></svg>");
                content: "";
                background-repeat: no-repeat;
                top: .55rem;
                position: absolute;
                margin-left: -1rem;
                height: 1rem;
                width: .5rem;
                @include media-breakpoint-down(lg) {
                    top: .5rem;
                }
            }
        }
    }
}

.title {
    &-different {
        font-size: $font-size-base * 1.8;
        padding-block-start: 1rem;
        margin-block: 1.5rem .5rem;
        @include media-breakpoint-down(sm) {
            font-size: $font-size-base * 1.5;
        }
    }
    &-line {
        position: relative;
        &::before {
            position: absolute;
            height: 3px;
            width: 3.5rem;
            content: "";
            top: 0;
            left: 0;
            background: #71bf44;
        }
    }
}

.list {
    @include list-unstyled();

    ul {
        @include list-unstyled();
        margin: .5rem 0 -.5rem;
    }

    li {
        position: relative;
        margin-left: .8rem;
        font-size: $font-size-base;
        //@include transition($transition-base);
        &:before {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 14'><path fill='#{$secondary}' d='M4.40535 12.293L5.5575 13.707L11.0225 6.99997L5.5575 0.292969L4.40535 1.70697L7.90335 5.99997H0.0925293V7.99997H7.90335L4.40535 12.293Z'></path></svg>");
                content: "";
                background-repeat: no-repeat;
                top: .7rem;
                position: absolute;
                margin-left: -.8rem;
                height: .9rem;
                width: .5rem;
                @include media-breakpoint-down(lg) {
                    top: .5rem;
                }
        }
    }


    &-columns {
        column-count: 2;
        li {
            page-break-inside: avoid;
            break-inside: avoid;
            display: table;
        }
    }
}
