/**
 * Page header
 */

.page-header {
    position: relative;
    padding: ($grid-gutter-width * 3.5) 0 0;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        padding: ($grid-gutter-width * 3) 0 0;
    }

    &.hashero {
        padding: ($grid-gutter-width * 2) 0 0;
    }

    &:not(.hashero) .shape-wrapper {
        margin-top: $grid-gutter-width;
    }

    &__text {
        font-size: $font-size-base;
        line-height: 1.625rem;
    }
    &--empty {
        padding-top: 9rem;
        @include media-breakpoint-down(sm) {
            padding-top: 4rem;
        }
    }
}

.page-header--line, .title-underlined {
    position: relative;
    padding-bottom: $spacer;

    &:after {
        height: 3px;
        width: 3.5rem;
        content: "";
        background: $secondary;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.hero {
    border-radius: 0 50% 50% 50%;
    position: relative;
    z-index: 1;
    max-width: 88%;
    margin: $grid-gutter-width / 2 auto;
}

.hero-header {
    background: $gray-200;
    height: 25rem;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
        height: 35rem;
    }
}

.hero-gallery {
    overflow: hidden;
    margin-top: 5.5rem;
    position: relative;
    background: $gray-100;
    @include media-breakpoint-up(lg) {
        margin-top: 6rem;
    }

    .slide {
        cursor: pointer;
        will-change: transform;
        &-last {
            position: relative;
        }
        img {
            transition: $transition-base;
        }
        &:nth-child(1) {
            &.slide-single {
                margin: 0 auto;
                width: 100%;
                // height: 25rem;
                float: none !important;
            }
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .slide {
            float: left;
            width: 25%;
            
            &:nth-child(1) {
                float: left;
                width: 50%;
                &.slide-single {
                    // height: 35rem;
                    float: none !important;
                    width: 100%;
                    // margin: 0 auto;
                    figure {
                        height: 720px;
                    }
                }
            }
            

            figure {
                // @include ratio(16 9);
                box-shadow: 0 0 0 .25rem $white;
            }
            &-extra {
                display: none;
            }
        }
    }
}

.hero-extra {
    background: #ffffff8a;
    color: $white;
    font-family: $font-family-header;
    position: absolute;
    right: 0;
    text-align: center;
    height: 50.2%;
    line-height: 2.5rem;
    width: 25%;
    top: 100%;
    transform: translateY(-100%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    &__number {
        font-size: 3rem;
    }

    &__text {
        font-size: 1.25rem;
        font-weight: $font-weight-medium;
    }
}
