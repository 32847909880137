.realisation {

    &-detail__title {
        font-size: 2rem;
        font-weight: normal;
        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
        }
    }

    &-address {
        h2 {
            font-size: $font-size-base;
        }
    }
}

.realisation-info--wrapper {
    max-width: 36rem;
}

.realisation-info--data {
    font-size: $h3-font-size;
    font-family: $font-family-sans-serif;
    color: $black;
}

.realisation-info--list {
    padding-left: 1.5 * $spacer;
}

.masonry {
    column-count: 3;
    column-gap: 2rem;
    column-fill: initial;
    @include media-breakpoint-down(lg) {
        column-count: 2;
    }

    @include media-breakpoint-down(sm) {
        column-count: 1;
    }

    &-box {
        transition: $transition-base;
        &:hover {
            & img {
                transform: scale(1.1);
            }
        }
    }
}

// Check this article --> https://css-tricks.com/exploring-css-grids-implicit-grid-and-auto-placement-powers/
.masonry--grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: dense;
    gap: $grid-gutter-width / 2;

    .masonry--item {
        aspect-ratio: 4/3;
        height: 100%;
        @include media-breakpoint-down(md) {
            &:nth-child(6n + 2) {
                grid-column: 2;
            }
            &:nth-child(6n + 3) {
                grid-column: 2;
            }
            &:nth-child(6n + 4) {
                grid-column: 1;
            }
            &:nth-child(6n + 5) {
                grid-column: 1;
            }
            &:nth-child(6n + 1), &:nth-child(6n + 6) {
                grid-row: span 2;
                height: 100%;
                width: 100%;
            }
        }
        @include media-breakpoint-only(md) {
            &:nth-child(9n + 2) {
                grid-column: 2;
            }
            &:nth-child(9n + 3) {
                grid-column: 2;
            }
            &:nth-child(9n + 5) {
                grid-column: 1;
            }
            &:nth-child(9n + 6) {
                grid-column: 1;
            }
            &:nth-child(9n + 8) {
                grid-column: 3;
            }
            &:nth-child(9n + 9) {
                grid-column: 3;
            }
            &:nth-child(9n + 1), &:nth-child(9n + 4), &:nth-child(9n + 7) {
                grid-row: span 2;
                height: 100%;
                width: 100%;
            }
            &:nth-child(9n + 9) {
                grid-column: 3;
            }
        }
        @include media-breakpoint-up(lg) {
            &:nth-child(12n + 2) {
                grid-column: 2;
            }
            &:nth-child(12n + 3) {
                grid-column: 2;
            }
            &:nth-child(12n + 5) {
                grid-column: 4;
            }
            &:nth-child(12n + 6) {
                grid-column: 4;
            }
            &:nth-child(12n + 7) {
                grid-column: 1;
            }
            &:nth-child(12n + 8) {
                grid-column: 1;
            }
            &:nth-child(12n + 10) {
                grid-column: 3;
            }
            &:nth-child(12n + 11) {
                grid-column: 3;
            }
            &:nth-child(12n + 1), &:nth-child(12n + 4), &:nth-child(12n + 9), &:nth-child(12n + 12) {
                grid-row: span 2;
                height: 100%;
                width: 100%;
            }
        }
    }
    .cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
        picture {
            height: 100%;
        }
    }
}
  


