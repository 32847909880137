/**
 * Gallery
 */

.gallery {
    overflow: hidden;
    .ratio {
        // @include border-radius($border-radius);
        overflow: hidden;
    }
    img {
        will-change: transform;
        @include transition($transition-base);
    }
    a:hover {
        img {
            transform: scale(1.1);
        }
    }
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    gap: $grid-gutter-width / 2;
}


