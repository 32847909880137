/**
 * Home
 */
.home {
    .section {
        overflow: hidden;

        // No idea why this is here, but it overrides the icon class everywhere so I'm commenting it ou just in case it pops up somewhere els
        // & .icon {
        //     width: 22rem;
        //     height: 8rem;
        //     margin-top: 3rem;
        //     max-width: 100%;
        //     @include media-breakpoint-down(md) {
        //         width: 17rem;
        //         height: 6rem;
        //     }
        // }
    }

    // .container {
    //     position: relative;
    //     z-index: 2;
    // }

    .list {
        margin-left: 25%;
        @include media-breakpoint-up(md) {
            min-height: 10rem;
        }
    }

    .shape {
        top: 25vh;
        bottom: auto;
        transform: rotate(180deg);
        z-index: 1;
    }
}
