.downArrow-wrapper {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    height: 75px;
    width: auto;
    z-index: 5;
    
    a {
        height: 100%;
        display: flex;
        align-self: stretch;
    }
}

.downArrow{
    display: flex;
    align-items: end;
    animation: bounce 3s infinite;
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($black, .25)
}

.frontbox {
    overflow: hidden;
    height: 90vh;
    @media screen and (max-height: 700px) {
        height: 37.5rem;
    }
    @include media-breakpoint-down(md) {
        height: 100vh;
    }

    h1 {
        text-shadow: 1px 1px 0px rgba($black, .75);
    }

    &__media {
        
        &--content {
            position: absolute;
            // min-height: 100%;
            min-width: 100%;
            
            video, 
            picture {
                object-fit: cover;
                width: 100%;
                // same (min-)height as the main frontbox class
                min-height: 90vh;
                height: 90vh;
                @include media-breakpoint-down(md) {
                    height: 100vh;
                }
                @media screen and (max-height: 700px) {
                    height: 37.5rem;
                }
            }
            video {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }
    }

    &-block {
        z-index: 1;
        h1 {
            max-width: 60rem;
        }
    }
}



@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }