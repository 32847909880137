/**
 * Icons
 */

.icon {
  width: 2.3rem;
  height: 2.3rem;

  @include media-breakpoint-down(md) {
    width: 2rem;
    height: 2rem;
  }

  &-arrow-left {
    margin-left: -.375rem;
  }

  &-arrow-right {
    margin-right: -.375rem;
  }

  &-search {
    fill: $white;
    width: 1rem;
    height: 3rem;

    @include media-breakpoint-down(lg) {
      fill: $black;
      width: 1.5rem;
      height: 1.2rem;
    }

    &:hover {
      fill: $primary;
    }
  }
}

.icon-before {
    &:before {
        content: "";
        background-repeat: no-repeat;
        position: absolute;
        height: 1.9rem;
        width: 1.9rem;
        margin-left: -1.7rem;
    }
}


.icon-small {
  width: 1.25rem;
  height: 1.25rem;
}

.icon-social {
  width: 2rem;
  height: 2rem;
}

.icon-grey-900 {
  fill: $gray-900;
}

.icon-light-grey {
  fill: $light-grey;
}

// class to add some margin to the icon if it accompanies a label
.icon-with-margin {
  margin-right:  .25rem;;
}


img.icon {
  margin: .5rem auto;
  height: 2rem;
  width: 2rem;

  @include media-breakpoint-up(md) {
    margin: .75rem auto;
    height: 3rem;
    width: 3rem;
  }
}

.logo-icon {
  @include logo-icon();
}

.icon-white {
  fill: $white;
  transition: $transition-base;
}
.icon-primary {
  fill: $primary;
}

.icon-secondary {
  fill: $secondary;
}

.icon-link,
.icon-link:hover {
    @include transition($transition-base); 
}

.icon-link-hover:hover {
  fill: shift-color($white, $link-shade-percentage);
}