/**
 * Images
 */

figure {
    background: $gray-200;
    margin: 0;
    overflow: hidden;
    position: relative;
}
figcaption {
    background: rgba($white,.4);
    position: absolute;
    bottom: 0;
    left: 0;
    // right: 0;
    text-align: left;
    padding: .25rem .75rem;
}

img {
    max-width: 100%;
}
picture {
    display: block;
}
.image, .ratio {
    img {
        width: 100%;
    }
}
//.image {
//    figure {
//        @include border-radius($border-radius);
//    }
//}

.lazyload, .lazyloading {
    opacity: 0;
}
.lazyloaded {
    opacity: 1;
    @include transition(opacity .2s ease-in);
}

@include media-breakpoint-up(md) {
    .cover {
        figure {
            border-radius: 0 10rem 10rem 0;
            width: calc(50vw - .75rem);
            height: 20rem;
            position: relative;
            max-width: none;
            float: right;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .col + .col {
        .cover {
            float: none;
            figure {
                float: none;
                border-radius: 10rem 0 0 10rem;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .cover {
        figure {
            border-radius: 0 15rem 15rem 0;
            height: 30rem;
        }
    }
    .col + .col {
        .cover {
            figure {
                border-radius: 15rem 0 0 15rem;
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .cover {
        figure {
            width: calc(100% + 5.625rem);
        }
    }
}
.col + .col {
    figcaption {
        left: auto;
        right: 0;
        text-align: right;
    }
}
