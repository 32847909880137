h1,
.h1 {
    font-family: $font-family-h1;
    font-weight: $font-weight-bold;
}

.section-title {
    font-family: $font-family-h1;
}

.h2-big {
    font-size: $font-size-root;
}

.text-small {
    font-size: .8rem;
}

.hover-transition {
    transition: .25s ease all;
}

.bg-light-color{
    background: $gray-100!important;
}

.bg-gradient-secondary {
    background:linear-gradient(260deg, rgba($secondary,1) 0%, rgba($secondary,0.8799894957983193) 100%);
}

.bg-grey-100 {
    background: $gray-100;
}

.text-light-grey{
    color: $light-grey;
}

.small-title {
    font-family: $btn-font-family;
}

.link-white a {
    color: $white;
}

// override for when the default text-decoration-none doesn't do the trick for overriding the default link text decoration
.link-decoration-none a {
    text-decoration: none;
}
// Add a hover effect a text
.text-link {
    @include transition($transition-base);
    &:hover {
        color: $secondary;
        // opacity: .75;
        @include transition($transition-base);
    }
}

.address-link{
    text-decoration: none;
    color:inherit;
}

// set the color of the € symbol
.text-currency::first-letter {
    color: $secondary;
    font-weight: $font-weight-bold;
}


.title-underline {
    position: relative;
    padding-bottom: 1rem;
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 3rem;
        height: .25rem;
        background: $secondary;
        left: 50%;
        transform: translateX(-50%);
    }
}

.hr-title {
    width: 3rem;
    opacity: 1;
    margin: 0.5rem 0 2rem;
}


li::marker {
    font-size: .5rem;
}

// .title-underlined {
//     position: relative;
//     margin-bottom: 2 * $spacer;
//     &:after {
//         content: "";
//         position: absolute;
//         bottom: -$spacer;
//         width: 3rem;
//         height: .25rem;
//         background: $secondary;
//     }
// }

.title-underlined--left {
    @extend .title-underlined;

    &:after {
        left: 0;
    }
}

.title-underlined--right {
    @extend .title-underlined;

    &:after {
        right: 0;
    }
}


.subtitle {
    font-weight: $font-weight-base;
    margin: 0 auto;
    width: 50%;

    @include media-breakpoint-down(md) {
        width: 100%;
    }
    &-testimonials {
        width: 40%;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    & a {
        color:$secondary;
        &:hover {
            color:$white;
            text-decoration: underline;
        }
    }
}

.small-green-text {
    color: $secondary;
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    text-align: center;
    font-family: $font-family-header;
    margin-bottom: 0.5rem;
}

.mobile-full-width {
    @include media-breakpoint-down(lg) {
        max-width: 100% !important;
        padding: 0;
    }
}



// image gallery
.owl-carousel {
    .owl-dots {
        bottom: 1.25rem;

        .owl-dot {
            &.active {
                span {
                    background: rgba($secondary, .75);
                }
            }

            span {
                background: rgba($white, .75);
            }
        }
    }

    .owl-nav {
        button {
            &.owl-prev,
            &.owl-next {
                background: transparent;

                &:hover {
                    background: rgba($secondary, .75);
                }
            }
        }
    }
}

// Make the project info sticky
.project-info--wrapper {
    @include media-breakpoint-down(md) {
        margin-top: 2rem;
    }
    @include media-breakpoint-up(md) {
        position: sticky;
        top: 7rem;
    }
}

.news-image {
    @extend .richtext;
    img {
        max-width: 100%;
        height: auto;
    }
}

.no-hover {
    pointer-events: none;
}


// Sweet 'n joyfull class to happy visualize a lucky area during development
.pink {
    border: 6px solid pink;
}
