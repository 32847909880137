.logo {
    margin: 0;
	padding: 0;
	position: relative;
    h2 {
        // background: url(../img/logo-purple-white.svg) 0 0 no-repeat;
        background-position: 0 0;
        background-repeat: no-repeat;
        margin: 0;
        width: 10rem;
        height: 0;
        background-size: auto 3.5rem;
        padding-top: 3.5rem;
        overflow: hidden;
        text-indent: -999em;
        @include media-breakpoint-up(lg) {
            width: 11rem;
            background-size: auto 4rem;
            padding-top: 4rem;
            @include transition($transition-base);
        }
    }
}

.logo-green_white h2 {
    background-image: url(../img/logo-green-white.svg);
}

.logo-purple_white h2 {
    background-image: url(../img/logo-purple-white.svg);
}