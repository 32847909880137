/**
 * Video
 */

.video .ratio {
    overflow: hidden;
}

.video-placeholder {
    background-color: #ccc;
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%;
    border-radius: .5rem;
  }

  .video-placeholder-inner {
    top: 0;
    background-color: #848484;
    border-radius: 15px;
    height: 50%;
    position: absolute;
    transform: translate(50%,50%);
    width: 50%;
  }

  .video-placeholder-dots {
    color: #fff;
    font-size: 3em;
    position: relative;
    top: 50%;
    left: 50%;
    margin: 0;
    text-align: center;
    transform: translate(-50%,-50%);
    @include media-breakpoint-up(sm) {
      font-size: 7.5em;
    }

  }

  p.video-placeholder-text {
    text-align: center;
    @include media-breakpoint-down(md) {
      margin-inline: 1rem;
      line-height: 1.5;
    }
  }
